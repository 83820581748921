<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                        <v-card-title class="title">
                            Activity Report
                            <v-spacer></v-spacer>
                            <download-button v-if="form.items.data.length" @action="printActivityReport">Download Activity Report</download-button>
                        </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs3 sm2>
                                <v-calendar-field
                                        id-val="frm_date"
                                        v-model="frmDate"
                                        label="From Date">
                                </v-calendar-field>
                            </v-flex>
                            <v-flex xs3 sm2>
                                <v-calendar-field
                                        id-val="toDate"
                                        v-model="toDate"
                                        label="To Date">
                                </v-calendar-field>
                            </v-flex>
                            <v-flex xs3 sm3>
                                <v-autocomplete
                                    outlined dense
                                        label="User"
                                        :items="auserList"
                                        v-model="auser"
                                        class="pa-0"
                                ></v-autocomplete>
                            </v-flex>

                            <v-flex xs3 sm5>
                                <v-select outlined dense :loading="activityLoading" :items="activityList"
                                          class="pa-0" label="Activity" v-model="activity"
                                />
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            :footer-props="footerProps"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left" style="width: 115px">
                                    {{ item.date }}
                                    <span>{{item.time}}</span> <br>
                                    <span style="font-size: 12px;color:#777;">{{getDateFormat(item.date)}}</span>

                                </td>
                                <td class="text-xs-left">{{ item.user }}</td>
                                <td class="text-xs-left">{{ typeof item.role === 'object' && typeof
                                    item.role!=='string' && (item.role && item.role.length) ?
                                    item.role.join(', ').toUpperCase() :
                                    item.role.length ? item.role.toUpperCase() :'-' }}
                                </td>
                                <td class="text-xs-left">{{ item.message }}</td>
                                <td class="text-xs-left">{{ item.action }}</td>
                                <td class="text-xs-center" v-if="item.channel === 'web'">
                                    <v-icon style="color:green; font-size:18px">language</v-icon>
                                </td>
                                <td class="text-xs-center" v-else>
                                    <v-icon style="color:red; font-size:18px">stay_primary_portrait</v-icon>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import {validateDate} from "../../../library/helpers";

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const ndT = new NepaliDate(tomorrow);
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            form: new Form({
                name: '',
                is_final: 0,
                exam_type: '',
            }, '/api/report/activity'),
            activityLoading: false,
            frmDate: nd.format('YYYY-MM-DD'),
            toDate: nd.format('YYYY-MM-DD'),
            auser: 'all',
            printUrl: '',
            activity: '',
            auserList: [],
            pagination: {
                itemsPerPage: 10
            },
            rowsPerPageItems:{
                'items-per-page-options' : [5, 10, 25, 50, 75],
            },
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            activityList: [],
            is_final: false,
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Date', align: 'left', value: 'date', width: 200, sortable: false},
                {text: 'User', align: 'left', value: 'user', sortable: false},
                {text: 'Role', align: 'left', value: 'role', sortable: false},
                {text: 'Message', align: 'left', value: 'message', sortable: false},
                {text: 'Action', align: 'left', value: 'action', sortable: false},
                {text: 'Channel', align: 'right', value: 'channel', sortable: false},
            ],

        }),

        computed: {
            ...mapState(['batch'])
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            // 'pagination.sortBy':function (v) {
            //     this.pagination.sortBy = v;
            //     this.get();
            // },
            'batch': function (value) {
                this.get();
            },
            'frmDate': function (v) {
                if (validateDate(v)) {
                    this.getActivityList();
                    this.get();
                }
            },
            'toDate': function (v) {
                if (validateDate(v)) {
                    this.getActivityList();
                    this.get();
                }

            },
            'auser': function () {
                this.form.items.data = [];
                this.get();
                this.activity = '';

            },
            'activity': function () {
                this.get();
            }
        },

        mounted() {
            this.getActivityList();
        },
        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&frmDate=' + this.frmDate + '&toDate=' + this.toDate + '&action=' + this.activity + '&auser=' + this.auser;
            },

            get(params) {
                // if (this.auser || this.activity) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                if (this.frmDate.length == 10 && this.toDate.length == 10) {
                    this.form.get(null, query).then(({data}) => {
                        this.pagination.totalItems = data.meta.total
                        this.printUrl = data.print_url;
                    })
                }
                // }
            },
            getActivityList() {
                this.frmDate = this.dateFormat(this.frmDate);
                this.toDate = this.dateFormat(this.toDate);

                if (this.frmDate.length == 10 && this.toDate.length == 10) {
                    this.$rest.get('/api/report/activity/action-list?frmDate=' + this.frmDate + '&toDate=' + this.toDate).then(({data}) => {
                        if (data.users.length > 0) {
                            this.auserList = data.users.map(res => {
                                return {value: res, text: res.toUpperCase()}
                            })
                        }
                        if (data.actions.length) {
                            this.activityList = data.actions.map(res => {
                                return {value: res, text: res.toUpperCase()};
                            })
                        }
                        this.auserList.push({value: 'all', text: 'ALL'})
                    });
                }

            },
            dateFormat(data) {
                if (data.length === 8) return data = data.substr(0, 4) + '-' + data[4] + data[5] + '-' + data.slice(-2);
                else return data;
            },

            getDateFormat(date) {
                try {
                    let dateInstance = new NepaliDate(new Date(date));
                    return dateInstance.format('mmmm d, yyyy dddd');
                } catch (e) {
                    this.updateState = false;
                    return '-';
                }
            },
            printActivityReport() {
                window.open(this.printUrl);
            }
        }
    }
</script>
<style lang="scss" scoped></style>